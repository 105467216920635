import Wrapper from '../../components/Wrapper';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import Pagination from '../../components/tablePagination/Pagination';
import SearchInput from '../../components/SearchInput';
import { PaginationConstants, PRODUCT_SORT_COL_MAP, ProductStatus, ProductStock } from '../../constants/variable';
import { Button } from '../../components/Button';
import CaretUp from '../../components/Icons/CaretUp';
import CaretDown from '../../components/Icons/CaretDown';
import CaretUpDown from '../../components/Icons/CaretUpDown';
import UsIcon from '../../components/Icons/UsIcon';
import CaIcon from '../../components/Icons/CaIcon';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../catalyst/table';
import { useToast } from '../../components/Toast/ToastProvider';
import { Avatar } from '../../catalyst/avatar';
import ListIconBlack from '../../components/Icons/ListIconBlack';
import DropdownComponent, {
  DropdownItem,
} from '../../components/DropdownComponent/DropdownComponent';
import MultiDropdownWithSearch, {
  MultiDropdownItem,
} from '../../components/MultiDropdownWithSearch/MultiDropdownWithSearch';
import DismissBadge from '../../components/DismissBadge';
import { Badge } from '../../catalyst/badge';

type ProductFilterOptions = {
  value: string;
  label: string;
};

type ProductFilter = {
  key: string;
  label: string;
  type: string;
  options: ProductFilterOptions[];
};

const ProductsListAdmin = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem('ACCESS_TOKEN');
  const toast = useToast();
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState<string>();
  const [count, setCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setlastPage] = useState(1);
  const [orderBy, setOrderBy] = useState<string>(PRODUCT_SORT_COL_MAP.default);
  const [orderByType, setOrderByType] = useState<string>('asc');
  const [showFilters, setShowFilters] = useState(false);
  const [productFilterData, setProductFilterData] = useState<ProductFilter[]>();
  const [preFilterData, setPreFilterData] = useState<DropdownItem[]>();
  const [subFilterData, setSubFilterData] = useState<MultiDropdownItem[]>();
  const [selectedFilter, setSelectedFilter] = useState<DropdownItem | undefined>();
  const [selectedSubFilter, setSelectedSubFilter] = useState<MultiDropdownItem[]>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hideDropdownOptions, setHideDropdownOptions] = useState<boolean>(false);
  const filterTypePlaceholderMap = new Map<string, string>();

  useEffect(() => {
    fetchProducts(search, true);
  }, [search, searchParams, orderBy, orderByType]);

  useEffect(() => {
    fetchProducts(search, false);
  }, [currentPage]);

  useEffect(() => {
    updateSearchParams();
  }, [selectedSubFilter]);

  const onToggle = (isOpen: boolean) => {
    setShowFilters(!isOpen);
    // If isOpen=true then it means close action is triggered
    if (isOpen) {
      setSelectedSubFilter([]);
      setSubFilterData([]);
      setSelectedFilter(undefined);
    }
  };

  const handleFilter = (selectedItem: DropdownItem) => {
    resetSubFilterData(selectedItem.id);
    setSelectedFilter(selectedItem);
    setHideDropdownOptions(false);
  };

  const toggleOption = (selectedItem: MultiDropdownItem) => {
    setSelectedSubFilter((prevSelected) => {
      // if it's in, remove
      let newArray: MultiDropdownItem[] = [];
      if (prevSelected) {
        newArray = [...prevSelected];
      }

      const found = arrayIncludes(newArray, selectedItem);

      if (found) {
        return newArray.filter(
          (item) =>
            item.value != selectedItem.value ||
            (item.value == selectedItem.value && item.parentKey != selectedItem.parentKey),
        );
        // else, add
      } else {
        newArray.push(selectedItem);
        return newArray;
      }
    });
  };

  const clearSubFilter = (parentKey: string) => {
    setSelectedSubFilter((prevSelected) => {
      let newArray: MultiDropdownItem[] = [];
      if (prevSelected) {
        newArray = [...prevSelected];
      }
      return newArray.filter((item) => item.parentKey != parentKey);
    });
  };

  const arrayIncludes = (list: MultiDropdownItem[], item: MultiDropdownItem) => {
    let found = false;
    for (const arrayItem of list) {
      if (arrayItem.value == item.value && arrayItem.parentKey == item.parentKey) {
        found = true;
        break;
      }
    }

    return found;
  };

  const clearAll = () => {
    setSelectedSubFilter([]);
    setSubFilterData([]);
    setSelectedFilter(undefined);
  };

  const fetchProducts = async (search: string | undefined, resetPage: boolean) => {
    const params: any = {};
    params.page = currentPage;
    params.search = search;

    if (orderBy !== '' && orderByType !== '') {
      params.orderBy = orderBy;
      params.orderByType = orderByType;
    }

    if (searchParams.size > 0) {
      searchParams.forEach((value, key) => {
        const itemList = value.split(',');
        const updatedValues = [];
        for (const val of itemList) {
          const valueList = val.split('|');
          updatedValues.push(valueList[0]);
        }
        value = updatedValues.join(',');
        params[key] = value;
      });
    }

    // We only want active and in stock Products in listing
    params.status = ProductStatus.ACTIVE
    params.stock = ProductStock.IN_STOCK

    const res = await axios
      .get(baseUrl + '/api/products/list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          setCount(data.data.pagination.total_records);
          setProducts(data.data.data);
          const totalPageCount = Math.ceil(
            parseInt(data.data.pagination.total_records) / PaginationConstants.ItemsPerPage,
          );
          setlastPage(totalPageCount);
          if (resetPage) {
            setCurrentPage(1);
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
        }
        console.log(error.response.data.error);
      });
  };

  const searchData = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setSearch(undefined);
    } else {
      setSearch(event.target.value);
    }
  };

  const applySorting = (columnName: string) => {
    if (orderBy === columnName && orderByType === 'asc') {
      setOrderBy(columnName);
      setOrderByType('desc');
      setCurrentPage(1);
    } else {
      setOrderBy(columnName);
      setOrderByType('asc');
      setCurrentPage(1);
    }
  };

  // not used
  function hide(data: any): void {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .put(baseUrl + '/api/admin/product/' + data.product_id + '/hide', {}, config)
      .then((response) => {
        fetchProducts(search, false);
        toast?.pushSuccess('Product will now be hidden!', 5000);
      })
      .catch((error) => {
        toast?.pushError('Update failed! Please try again.', 5000);
        console.log(error.response.data.error);
      });
  }

  // not used
  function show(data: any): void {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .put(baseUrl + '/api/admin/product/' + data.product_id + '/show', {}, config)
      .then((response) => {
        fetchProducts(search, false);
        toast?.pushSuccess('Product will now be available!', 5000);
      })
      .catch((error) => {
        toast?.pushError('Update failed! Please try again.', 5000);
        console.log(error.response.data.error);
      });
  }

  const resetSubFilterData = (selectedKey: string) => {
    if (productFilterData) {
      const dataList: MultiDropdownItem[] = [];
      for (const data of productFilterData) {
        if (data.key === selectedKey) {
          for (const opt of data.options) {
            const dataTempObj: MultiDropdownItem = {
              value: opt.value,
              label: opt.label,
              parentKey: selectedKey,
            };
            dataList.push(dataTempObj);
          }
          break;
        }
        if (dataList.length > 0) {
          break;
        }
      }
      setSubFilterData(dataList);
    }
  };

  const fetchPreFilterData = async (source: any) => {
    const res = await axios
      .get(baseUrl + '/api/config/filter/product', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setProductFilterData(response.data.data);
        const dataList: DropdownItem[] = [];
        for (const data of response.data.data) {
          const filterObj = {
            id: data.key,
            name: data.label,
          };
          dataList.push(filterObj);
        }
        const filteredDataList = dataList.filter((data: any) =>
          data?.id !== 'status' &&
          data?.id !== 'stock'
      )

        setPreFilterData(filteredDataList);
        return response.data;
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  };

  const updateSearchParams = () => {
    const paramMap = new Map<string, string>();
    if (selectedSubFilter) {
      for (const item of selectedSubFilter) {
        const mapValue = paramMap.get(item.parentKey);
        if (mapValue) {
          const newValue = `${mapValue},${item.value}|${item.label}`;
          paramMap.set(item.parentKey, newValue);
        } else {
          const newValue = `${item.value}|${item.label}`;
          paramMap.set(item.parentKey, newValue);
        }
      }
    }

    if (paramMap.size > 0) {
      const searchParamObj: any = {};

      for (const [key, value] of paramMap) {
        searchParamObj[key] = value;
      }

      setSearchParams(searchParamObj);
    } else {
      setSearchParams({});
    }
  };

  const prefillData = async () => {
    if (searchParams.size > 0) {
      const subFilterList: MultiDropdownItem[] = [];
      const selectedDateValue: any = {};
      const selectedSubFilterObj: any = { id: '', name: '' };
      searchParams.forEach((value, key) => {
        switch (key) {
          case 'start_date':
            selectedDateValue.startDate = decodeURI(value);
            break;
          case 'end_date':
            selectedDateValue.endDate = decodeURI(value);
            break;
          case 'date_filter':
            // do nothing
            break;
          default: {
            if (selectedSubFilterObj?.id == '') {
              selectedSubFilterObj.id = key;
            }
            const itemList = value.split(',');
            for (const val of itemList) {
              const valueList = val.split('|');
              const selectedItemObj: MultiDropdownItem = {
                parentKey: key,
                label: valueList[1],
                value: valueList[0],
              };
              subFilterList.push(selectedItemObj);
            }
          }
        }
      });
      setSelectedSubFilter(() => {
        return subFilterList;
      });
      if (selectedSubFilterObj.id != '') {
        setSelectedFilter(selectedSubFilterObj);
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchPreFilterData(source);
    prefillData();
    setHideDropdownOptions(true);
    return () => source.cancel();
  }, []);

  useEffect(() => {
    if (!selectedFilter || !preFilterData) {
      return;
    }
    if (selectedFilter.id != '' && selectedFilter.name == '') {
      for (const orderFilterObj of preFilterData) {
        if (orderFilterObj.id == selectedFilter.id) {
          resetSubFilterData(orderFilterObj.id);
          setSelectedFilter(orderFilterObj);
          setShowFilters(true);
          break;
        }
      }
    }
  }, [prefillData]);

  return (
    <>
      <Wrapper className='flex flex-col px-20'>
        <div className='flex justify-between'>
          <div className='flex-auto'>
            <p className='text-left text-2xl font-semibold'>Products ({count})</p>
          </div>
        </div>
        <div className='flex justify-between py-3'>
          <div className='relative mr-5 flex h-12 w-full items-center overflow-hidden rounded-lg border border-gray-200 bg-white drop-shadow-lg focus-within:shadow-lg'>
            <SearchInput
              onChange={searchData}
              search={search}
              placeholder='Search by title'
            ></SearchInput>
          </div>
          <div className='w-fit'>
            <Button
              type='solidWithBorder'
              className='focus:none border border-gray-200 bg-white text-gray-800 drop-shadow-lg hover:bg-gray-200'
              rounded={true}
              onClick={() => onToggle(showFilters)}
            >
              <ListIconBlack></ListIconBlack> <span className='text-gray-800'>Filter</span>
            </Button>
          </div>
        </div>
        {showFilters && (
          <div className='z-100 flex flex-row flex-wrap items-center pb-2'>
            {preFilterData && (
              <div className='mr-2 w-36 items-center'>
                <DropdownComponent
                  id='filterdropdown'
                  title='Add Filter'
                  data={preFilterData}
                  onSelect={handleFilter}
                  listHeader='CATEGORIES'
                  selectedId={selectedFilter?.id}
                />
              </div>
            )}
            {subFilterData && subFilterData.length > 0 && (
              <div className='mr-2 w-52 items-center'>
                <MultiDropdownWithSearch
                  id='subfilterdropdown'
                  title={selectedFilter?.name}
                  data={subFilterData}
                  parentKey={selectedFilter ? selectedFilter?.id : ''}
                  onSelect={toggleOption}
                  clearSubFilter={clearSubFilter}
                  selected={selectedSubFilter}
                  searchPlaceholder={filterTypePlaceholderMap.get(
                    selectedFilter ? selectedFilter.id : '',
                  )}
                  hideDropdownOptions={hideDropdownOptions}
                />
              </div>
            )}
            {selectedSubFilter && !!selectedSubFilter.length && (
              <>
                {selectedSubFilter.map((filter) => (
                  <div key={filter.value} className='flex items-center gap-2 py-2'>
                    <DismissBadge
                      badgeText={filter.label}
                      onDismiss={() => toggleOption(filter)}
                    />
                  </div>
                ))}
                <button
                  className='h-8 w-28 rounded-full bg-white px-2 py-1 text-sm font-medium leading-4 tracking-wide text-gray-800'
                  onClick={() => clearAll()}
                >
                  Clear all
                </button>
              </>
            )}
          </div>
        )}
        <div className='py-4'>
          <Table className='relative min-w-full divide-y divide-gray-200 border-b border-gray-200 shadow drop-shadow-lg sm:rounded-lg'>
            <TableHead className='overflow-hidden bg-gray-50'>
              <TableRow>
                <TableHeader className='w-5'></TableHeader>
                <TableHeader className='w-1/4 px-2 text-gray-500'>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(PRODUCT_SORT_COL_MAP.title)}
                  >
                    <span className='mr-1'>TITLE</span>
                    {orderBy === PRODUCT_SORT_COL_MAP.title ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='text-gray-500'>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(PRODUCT_SORT_COL_MAP.sku)}
                  >
                    <span className='mr-1'>SKU</span>
                    {orderBy === PRODUCT_SORT_COL_MAP.sku ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='text-gray-500'>COUNTRIES</TableHeader>
                <TableHeader className='text-gray-500'>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(PRODUCT_SORT_COL_MAP.quantity_ordered)}
                  >
                    <span className='mr-1'>QTY ORDERED</span>
                    {orderBy === PRODUCT_SORT_COL_MAP.quantity_ordered ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='text-gray-500'>
                <button
                    className='flex items-center'
                    onClick={() => applySorting(PRODUCT_SORT_COL_MAP.quantity_in_stock)}
                  >
                    <span className='mr-1'>QTY IN STOCK</span>
                    {orderBy === PRODUCT_SORT_COL_MAP.quantity_in_stock ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                {/* <TableHeader className='text-gray-500'>
                <button
                    className='flex items-center'
                    onClick={() => applySorting(PRODUCT_SORT_COL_MAP.status)}
                  >
                    <span className='mr-1'>STATUS</span>
                    {orderBy === PRODUCT_SORT_COL_MAP.status ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader> */}
                {/* <TableHeader className='text-gray-500'>ACTIONS</TableHeader> */}
              </TableRow>
            </TableHead>
            <TableBody className='overflow-hidden bg-white'>
              {products.map((data: any) => (
                <TableRow key={data.id}>
                  <TableCell className="w-5">
                    <div className="flex items-center">
                      <div className='rounded-xl border bg-gray-200 '>
                        <Avatar square src={data.image?.thumbnail} className='size-12' />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className='w-1/4 text-gray-800'>
                    {data.title}
                  </TableCell>
                  <TableCell className='text-gray-600'>{data.sku}</TableCell>
                  <TableCell className='text-gray-600'>
                    <div className='flex items-center gap-2'>
                      {data.countries.includes('CA') && <CaIcon></CaIcon>}
                      {data.countries.includes('US') && <UsIcon></UsIcon>}
                    </div>
                  </TableCell>
                  <TableCell className='text-gray-800'>{data.quantity_ordered || 0}</TableCell>
                  <TableCell className='text-gray-800'>{data.digital ? 'Unlimited' : data.quantity_in_stock}</TableCell>
                  {/* <TableCell className='text-gray-800'>
                    <Badge
                      color={
                        data.status == ProductStatus.DRAFT
                          ? 'blue'
                            : data.status == ProductStatus.ACTIVE
                              ? 'greenTxtWht'
                              : 'red'
                      }
                    >
                      {data.status}
                    </Badge>
                  </TableCell> */}
                  {/* <TableCell>
                    <span className='inline-flex items-center'>
                      { data.status !== ProductStatus.ARCHIVED && (
                        data.status === ProductStatus.ACTIVE ?
                          <span
                            className='item cursor-pointer text-gray-500'
                            onClick={() => hide(data)}
                          >
                            Unlist
                          </span>
                        :
                          <span
                            className='item cursor-pointer text-gray-500'
                            onClick={() => show(data)}
                          >
                            List
                          </span>
                      )}
                    </span>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {products && products.length == 0 && (
            <div className='pt-5 text-center text-gray-500'>{'No products found'}</div>
          )}
          {products && products.length > 0 && (
            <div className='mb-5 mt-8 flex h-10 items-center justify-center'>
              <Pagination
                currentPage={currentPage}
                lastPage={lastPage}
                maxLength={5}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </Wrapper>
    </>
  );
};

export default ProductsListAdmin;
