export const countries = [
  {
    name: 'United States',
    value: 'US',
    states: [
      {
        name: 'Alabama',
        value: 'AL',
      },
      {
        name: 'Alaska',
        value: 'AK',
      },
      {
        name: 'American Samoa',
        value: 'AS',
      },
      {
        name: 'Arizona',
        value: 'AZ',
      },
      {
        name: 'Arkansas',
        value: 'AR',
      },
      {
        name: 'California',
        value: 'CA',
      },
      {
        name: 'Colorado',
        value: 'CO',
      },
      {
        name: 'Connecticut',
        value: 'CT',
      },
      {
        name: 'Delaware',
        value: 'DE',
      },
      {
        name: 'District Of Columbia',
        value: 'DC',
      },
      {
        name: 'Federated States Of Micronesia',
        value: 'FM',
      },
      {
        name: 'Florida',
        value: 'FL',
      },
      {
        name: 'Georgia',
        value: 'GA',
      },
      {
        name: 'Guam',
        value: 'GU',
      },
      {
        name: 'Hawaii',
        value: 'HI',
      },
      {
        name: 'Idaho',
        value: 'ID',
      },
      {
        name: 'Illinois',
        value: 'IL',
      },
      {
        name: 'Indiana',
        value: 'IN',
      },
      {
        name: 'Iowa',
        value: 'IA',
      },
      {
        name: 'Kansas',
        value: 'KS',
      },
      {
        name: 'Kentucky',
        value: 'KY',
      },
      {
        name: 'Louisiana',
        value: 'LA',
      },
      {
        name: 'Maine',
        value: 'ME',
      },
      {
        name: 'Marshall Islands',
        value: 'MH',
      },
      {
        name: 'Maryland',
        value: 'MD',
      },
      {
        name: 'Massachusetts',
        value: 'MA',
      },
      {
        name: 'Michigan',
        value: 'MI',
      },
      {
        name: 'Minnesota',
        value: 'MN',
      },
      {
        name: 'Mississippi',
        value: 'MS',
      },
      {
        name: 'Missouri',
        value: 'MO',
      },
      {
        name: 'Montana',
        value: 'MT',
      },
      {
        name: 'Nebraska',
        value: 'NE',
      },
      {
        name: 'Nevada',
        value: 'NV',
      },
      {
        name: 'New Hampshire',
        value: 'NH',
      },
      {
        name: 'New Jersey',
        value: 'NJ',
      },
      {
        name: 'New Mexico',
        value: 'NM',
      },
      {
        name: 'New York',
        value: 'NY',
      },
      {
        name: 'North Carolina',
        value: 'NC',
      },
      {
        name: 'North Dakota',
        value: 'ND',
      },
      {
        name: 'Northern Mariana Islands',
        value: 'MP',
      },
      {
        name: 'Ohio',
        value: 'OH',
      },
      {
        name: 'Oklahoma',
        value: 'OK',
      },
      {
        name: 'Oregon',
        value: 'OR',
      },
      {
        name: 'Palau',
        value: 'PW',
      },
      {
        name: 'Pennsylvania',
        value: 'PA',
      },
      {
        name: 'Puerto Rico',
        value: 'PR',
      },
      {
        name: 'Rhode Island',
        value: 'RI',
      },
      {
        name: 'South Carolina',
        value: 'SC',
      },
      {
        name: 'South Dakota',
        value: 'SD',
      },
      {
        name: 'Tennessee',
        value: 'TN',
      },
      {
        name: 'Texas',
        value: 'TX',
      },
      {
        name: 'Utah',
        value: 'UT',
      },
      {
        name: 'Vermont',
        value: 'VT',
      },
      {
        name: 'Virgin Islands',
        value: 'VI',
      },
      {
        name: 'Virginia',
        value: 'VA',
      },
      {
        name: 'Washington',
        value: 'WA',
      },
      {
        name: 'West Virginia',
        value: 'WV',
      },
      {
        name: 'Wisconsin',
        value: 'WI',
      },
      {
        name: 'Wyoming',
        value: 'WY',
      },
    ],
  },
  {
    name: 'Canada',
    value: 'CA',
    states: [
      {
        name: 'Alberta',
        value: 'AB',
      },
      {
        name: 'British Columbia',
        value: 'BC',
      },
      {
        name: 'Manitoba',
        value: 'MB',
      },
      {
        name: 'New Brunswick',
        value: 'NB',
      },
      {
        name: 'Newfoundland and Labrador',
        value: 'NL',
      },
      {
        name: 'Northwest Territories',
        value: 'NT',
      },
      {
        name: 'Nova Scotia',
        value: 'NS',
      },
      {
        name: 'Nunavut',
        value: 'NU',
      },
      {
        name: 'Ontario',
        value: 'ON',
      },
      {
        name: 'Prince Edward Island',
        value: 'PE',
      },
      {
        name: 'Quebec',
        value: 'QC',
      },
      {
        name: 'Saskatchewan',
        value: 'SK',
      },
      {
        name: 'Yukon Territory',
        value: 'YT',
      },
    ],
  },
];

export const PaginationConstants = {
  ItemsPerPage: 20,
};

export const US_ZIPCODE_REGEX = /^\d{5}(-\d{4})?$/;
export const CANADA_ZIPCODE_REGEX = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
export const EMAIL_REGEX = /[a-zA-Z0-9.\-+_]+@([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{1,63}/;
export const PASSWORD_REGEX =
  /^(?=.*([A-Z]){1,})(?=.*?[#?!@$%^&*-]{1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{6,100}$/;
export const PHONE_REGEX = /^\+[1-9]\d{10}$/;

export const AddressFields = [
  'address_1',
  'address_2',
  'city',
  'state',
  'zipcode',
  'country',
  'address_id',
];

export const AutofillRecipientFields = [
  'company',
  'internal_id'
]

export const TEMPLATE_CHAR_LIMIT_DEFAULT = 450;

export const OrderStatus = {
  SUCCESS: 'success',
  ERROR: 'error',
  PARTIAL: 'partail',
};

export const OrderDBStatus = {
  SHIPMENT_DELIVERED: 'Delivered',
  ORDER_CREATED: 'Processing',
  SHIPPED: 'Shipped',
  NOT_DELIVERED: 'Not delivered',
  CANCELLED: 'Cancelled',
};

export const ProductStatus = {
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
}

export const ProductStock = {
  IN_STOCK: 'IN_STOCK',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
}

export const ShopifyStatus = {
  SHIPMENT_DELIVERED: 'Delivered',
  ORDER_CREATED: 'Processing',
  SHIPPED: 'Shipped',
  NOT_DELIVERED: 'Not delivered',
  CANCELLED: 'Cancelled',
};

export const QuotaRequestStatus = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
}

export const USER_SORT_COL_MAP = {
  status: "status",
  name: "name",
  email: "email",
  last_active: "last_active",
  default: "last_active",
};

export const TEMPLATE_SORT_COL_MAP = {
  title: "title",
  content: "content",
  updated_at: "updated_at",
  default: "updated_at",
};

export const ORDER_SORT_COL_MAP = {
  sales_rep: "sales_rep",
  recepient: "recepient",
  gift: "gift",
  status: "status",
  updated_at: "updated_at",
  company: "company",
  default: "updated_at",
  created_at: "created_at",
};

export const PRODUCT_SORT_COL_MAP = {
  title: "title",
  sku: "sku",
  country: "country",
  quantity_in_stock: "quantity_in_stock",
  quantity_ordered: "quantity_ordered",
  status: "status",
  default: "title",
}

export const QUOTA_REQUEST_SORT_COL_MAP = {
  sales_rep: "sales_rep",
  reason: "reason",
  status: "status",
  created_at: "created_at",
  updated_at: "updated_at",
  updated_by: "updated_by",
  default: "created_at",
}

export const GENERAL_ENQUIRY_FORM_URL = "https://3725513.extforms.netsuite.com/app/site/crm/externalcasepage.nl/compid.3725513/.f?formid=3&h=AAFdikaIo97obnrjQLwRrPV9ku6M2iERAL_AoA0xKI8vwbBlRnU&redirect_count=1&did_javascript_redirect=T&storeid=oracle-netsuite-portal";
export const ORDER_ENQUIRY_FORM_URL = "https://3725513.extforms.netsuite.com/app/site/crm/externalcasepage.nl/compid.3725513/.f?formid=2&h=AAFdikaIxyicoBa-BI0tEWZKeIS2Io293SqdGXpCRx056VWE7v4&redirect_count=1&did_javascript_redirect=T";
