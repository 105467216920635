import Wrapper from '../../components/Wrapper';
import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, createSearchParams, Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import React from 'react';
import { useForm } from 'react-hook-form';
import Pagination from '../../components/tablePagination/Pagination';
import { useModal } from '../../hooks/useModal';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../catalyst/table';
import { Badge } from '../../catalyst/badge';
import EditIcon from '../../components/Icons/EditIcon';
import ListIcon from '../../components/Icons/ListIcon';
import MailIcon from '../../components/Icons/MailIcon';
import UsIcon from '../../components/Icons/UsIcon';
import CaIcon from '../../components/Icons/CaIcon';
import SeperatorIcon from '../../components/Icons/SeperatorIcon';
import SearchInput from '../../components/SearchInput';
import { format } from 'date-fns';
import InputFormField from '../../components/InputFormField';
import { Input, InputGroup } from '../../catalyst/input';
import { PaginationConstants, USER_SORT_COL_MAP } from '../../constants/variable';
import { Switch, SwitchField } from '../../catalyst/switch';
import { Description, Field, Label } from '../../catalyst/fieldset';
import { useToast } from '../../components/Toast/ToastProvider';
import Datepicker from 'react-tailwindcss-datepicker';
import DropdownComponent, {
  DropdownItem,
} from '../../components/DropdownComponent/DropdownComponent';
import MultiDropdownWithSearch, {
  MultiDropdownItem,
} from '../../components/MultiDropdownWithSearch/MultiDropdownWithSearch';
import DismissBadge from '../../components/DismissBadge';
import ListIconBlack from '../../components/Icons/ListIconBlack';
import DropdownQuotaComponent, {
  UserFilterQuota,
  UserFilterQuotaOptions,
} from '../../components/DropdownQuotaComponent/DropdownQuotaComponent';
import ExclemationCircleFill from '../../components/Icons/ExclamationCircleFill';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { cx } from 'cva';
import CaretUpGreenIcon from '../../components/Icons/CaretUpGreenIcon';
import CaretDownRedIcon from '../../components/Icons/CaretDownRedIcon';
import TooltipComponent from '../../components/Tooltip';
import CaretUp from '../../components/Icons/CaretUp';
import CaretDown from '../../components/Icons/CaretDown';
import CaretUpDown from '../../components/Icons/CaretUpDown';
import Loader from '../../components/Icons/Loader';
import SelectInput from '../../components/SelectInput';

const FormData = require('form-data');

type FormValues = {
  firstName: string;
  lastName: string;
  accountType: string;
  email: string;
  quotaUs: number;
  quotaCa: number;
  role: string;
  status: string;
};

const defaultAddUserValues = {
  firstName: '',
  lastName: '',
  accountType: '',
  email: '',
  quotaUs: 0,
  quotaCa: 0,
  role: '',
  status: '',
};

type UserFilterOptions = {
  value: string;
  label: string;
};

type UserFilter = {
  key: string;
  label: string;
  type: string;
  options: UserFilterOptions[] | UserFilterQuota[];
};

const baseUrl = process.env.REACT_APP_BASE_URL;
const UsersList = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const token = localStorage.getItem('ACCESS_TOKEN');
  const [count, setCount] = useState([]);
  const [search, setSearch] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setlastPage] = useState(1);
  const [dateValue, setDateValue] = useState<any>({});
  const [showFilters, setShowFilters] = useState(false);
  const [orderFilterData, setOrderFilterData] = useState<UserFilter[]>();
  const [preFilterData, setPreFilterData] = useState<DropdownItem[]>();
  const [subFilterData, setSubFilterData] = useState<MultiDropdownItem[]>();
  const [subFilterQuotaData, setSubFilterQuotaData] = useState<UserFilterQuota[]>();
  const [selectedFilter, setSelectedFilter] = useState<DropdownItem | undefined>();
  const [selectedSubFilter, setSelectedSubFilter] = useState<MultiDropdownItem[]>();
  const [selectedSubFilterQuota, setSelectedSubFilterQuota] = useState<UserFilterQuota[]>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeUsersWidget, setActiveUsersWidget] = useState<any>();
  const [activeUsersWidgetData, setActiveUsersWidgetData] = useState();
  const [avgUsageUsersWidget, setAvgUsageUsersWidget] = useState<any>();
  const [quotaExhaustUsersWidget, setQuotaExhaustUsersWidget] = useState<any>();
  const [orderBy, setOrderBy] = useState<string>(USER_SORT_COL_MAP.default);
  const [orderByType, setOrderByType] = useState<string>('desc');
  const [hideDropdownOptions, setHideDropdownOptions] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [selectedAllUsers, setSelectedAllUsers] = useState(false);
  const [bulkUploadFile, setBulkUploadFile] = useState<any>(null);
  const [bulkUploadError, setBulkUploadError] = useState<string>('');
  const [bulkUploadResponse, setBulkUploadResponse] = useState<any>({});
  const [bulkUploadInProgress, setBulkUploadInProgress] = useState(false);
  const [reportGenerationInProgress, setReportGenerationInProgress] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [monthlyReportErrors, setmonthlyReportErrors] = useState<string>('');

  const filterTypePlaceholderMap = new Map<string, string>();
  filterTypePlaceholderMap.set('sales_rep', 'Search by name or email');
  filterTypePlaceholderMap.set('status', 'Search by status');

  const getErrorClass = (errors: any, name: string) => {
    return errors && errors[name] ? 'border-red-500 shadow-md shadow-red-200' : 'border-gray-200';
  };

  const getClass = (name: string) => {
    const classes = cx('block w-full rounded-lg px-4 py-3 text-sm', getErrorClass(errors, name));
    return classes;
  };

  const {
    register,
    reset,
    trigger,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: defaultAddUserValues,
  });

  const handleValueChange = (newValue: any) => {
    setDateValue(newValue);
  };

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchPreFilterData(source);
    prefillData();
    setHideDropdownOptions(true);
    return () => source.cancel();
  }, []);

  const prefillData = async () => {
    if (searchParams.size > 0) {
      const subFilterList: MultiDropdownItem[] = [];
      const selectedDateValue: any = {};
      const selectedSubFilterObj: any = { id: '', name: '' };
      searchParams.forEach((value, key) => {
        switch (key) {
          case 'start_date':
            selectedDateValue.startDate = decodeURI(value);
            break;
          case 'end_date':
            selectedDateValue.endDate = decodeURI(value);
            break;
          case 'date_filter':
            // do nothing
            break;
          case 'quota': {
            const decodedObj = JSON.parse(decodeURI(value));
            const quotaFilter: UserFilterQuota[] = [];
            Object.keys(decodedObj).forEach(function (key) {
              const val = decodedObj[key];
              const optionsList: UserFilterQuotaOptions[] = [];
              Object.keys(val).forEach(function (optionsKey) {
                const optionValue = val[optionsKey];
                if (optionsKey != 'parentKey') {
                  const optionVal: UserFilterQuotaOptions = {
                    label: optionsKey,
                    value: optionValue,
                  };
                  optionsList.push(optionVal);
                }
              });
              const quotaFilterObj: UserFilterQuota = {
                key: key,
                parentKey: 'quota',
                label: val.parentKey,
                options: optionsList,
              };
              quotaFilter.push(quotaFilterObj);
            });
            setSelectedSubFilterQuota(quotaFilter);
            break;
          }
          default: {
            if (selectedSubFilterObj?.id == '') {
              selectedSubFilterObj.id = key;
            }
            const itemList = value.split(',');
            for (const val of itemList) {
              const valueList = val.split('|');
              const selectedItemObj: MultiDropdownItem = {
                parentKey: key,
                label: valueList[1],
                value: valueList[0],
              };
              subFilterList.push(selectedItemObj);
            }
          }
        }
      });
      setSelectedSubFilter(() => {
        return subFilterList;
      });
      if (selectedSubFilterObj.id != '') {
        setSelectedFilter(selectedSubFilterObj);
      }
      if (selectedDateValue.startDate != '' && selectedDateValue.endDate != '') {
        setDateValue(selectedDateValue);
      }
    }
  };

  useEffect(() => {
    if (!selectedFilter || !preFilterData) {
      return;
    }
    if (selectedFilter.id != '' && selectedFilter.name == '') {
      for (const orderFilterObj of preFilterData) {
        if (orderFilterObj.id == selectedFilter.id) {
          resetSubFilterData(orderFilterObj.id);
          setSelectedFilter(orderFilterObj);
          setShowFilters(true);
          break;
        }
      }
    }
  }, [prefillData]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchUsers(source, search, true);
    return () => source.cancel();
  }, [searchParams, search, orderBy, orderByType]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchActiveUserWidgets(source);
    fetchAvgUsageUserWidgets(source);
    fetchQuotaExhaustUserWidgets(source);
    return () => source.cancel();
  }, [searchParams]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchUsers(source, search, false);
    return () => source.cancel();
  }, [currentPage]);

  useEffect(() => {
    updateSearchParams();
  }, [selectedSubFilter, selectedSubFilterQuota, dateValue]);

  function formatDate(date: any) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  const dateDefault = {
    startDate: formatDate(new Date(new Date().setMonth(new Date().getMonth() - 1))),
    endDate: formatDate(new Date()),
  };

  useEffect(() => {
    const paramMap = new Map<string, string>();
    paramMap.set('date_filter', 'custom');
    return setDateValue(dateDefault);
  }, []);

  useEffect(() => {
    if(selectedMonth === '') {
      setSelectedMonth(months[new Date().getMonth()]);
    }
  });

  const accountType = [
    {
      value: 'AMO',
      name: 'AMO',
    },
    {
      value: 'Channel',
      name: 'Channel',
    },
    {
      value: 'Direct',
      name: 'Direct',
    },
    {
      value: 'SDR',
      name: 'SDR',
    },
    {
      value: 'Unassigned',
      name: 'Unassigned',
    },
  ];

  const userRole = [
    {
      value: 'USER',
      name: 'User'
    },
    {
      value: 'MANAGER',
      name: 'Manager'
    },
    {
      value: 'ADMIN',
      name: 'Team Lead'
    }
  ];

  const userStatus = [
    {
      value: 'Active',
      name: 'Active'
    },
    {
      value: 'Inactive',
      name: 'Inactive'
    }
  ];

  const [bulkEdit, setBulkEdit] = useState(false);
  const [selectedOption, setSelectedOption] = useState(accountType[0].value);
  const [selectedRole, setSelectedRole] = useState(userRole[0].value);
  const [selectedStatus, setSelectedStatus] = useState(userStatus[0].value);

  const searchData = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setSearch(undefined);
    } else {
      setSearch(event.target.value);
    }
  };

  const [state, setState] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    accountType: '',
    quotaUs: '',
    quotaCa: '',
    disabled: false,
    status: '',
    role: '',
  });

  function openEditModal(data: any): void {
    setState((prevState) => ({
      ...prevState,
      id: data.id,
      disabled: true,
      status: data.status,
    }));

    setValue('firstName', data.first_name);
    setValue('lastName', data.last_name);
    setValue('email', data.email);
    setValue('accountType', data.account_type);
    setValue('role', data.role);
    if (data.quota) {
      setValue('quotaUs', data.quota?.US?.monthly_quota || 0);
      setValue('quotaCa', data.quota?.CA?.monthly_quota || 0);
    }
    setSelectedOption(data.account_type);
    setSelectedRole(data.role);

    openModal();
  }

  function openEmptyStateModal() {
    setState((prevState) => ({
      ...prevState,
      id: '',
      disabled: false,
      status: '',
    }));
    setSelectedOption(accountType[0].value);
    setSelectedRole(userRole[0].value);
    reset(defaultAddUserValues);
    openModal();
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function redirectToUserOrder(data: any): void {
    const name = `${data.first_name} ${data.last_name}`;
    const valueStr = `${data.email}|${name}`;
    const dateDefault = {
      startDate: formatDate(new Date(new Date().setMonth(new Date().getMonth() - 1))),
      endDate: formatDate(new Date()),
    };

    navigate({
      pathname: '/admin/order',
      search: createSearchParams({
        sales_rep: valueStr,
        date_filter: 'custom',
        start_date: dateDefault.startDate,
        end_date: dateDefault.endDate,
      }).toString(),
    });
  }

  function sendInviteEmail(data: any): void {
    const obj = {
      user_id: data.id,
    };
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .put(baseUrl + '/api/admin/user/resendInvite', obj, config)
      .then((response) => {
        toast?.pushSuccess('Invite resent successfully!', 5000);
        onModalClose();
        fetchUsers(undefined, search, true);
      })
      .catch(console.log);
  }

  const fetchActiveUserWidgets = async (source: any) => {
    const params: any = {};
    const dateFilter = searchParams.get('date_filter');
    const startDate = searchParams.get('start_date');
    const endDate = searchParams.get('end_date');
    if (!dateFilter || !startDate || !endDate) {
      setActiveUsersWidget(undefined);
      setActiveUsersWidgetData(undefined);
      return;
    }
    params.date_filter = dateFilter;
    params.start_date = startDate;
    params.end_date = endDate;

    const res = await axios
      .get(baseUrl + `/api/admin/user/widget/active-users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data && data.data) {
          setActiveUsersWidget(data.data);
          setActiveUsersWidgetData(data.data.data);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  };

  const fetchAvgUsageUserWidgets = async (source: any) => {
    const params: any = {};
    const dateFilter = searchParams.get('date_filter');
    const startDate = searchParams.get('start_date');
    const endDate = searchParams.get('end_date');
    if (!dateFilter || !startDate || !endDate) {
      setAvgUsageUsersWidget(undefined);
      return;
    }
    params.date_filter = dateFilter;
    params.start_date = startDate;
    params.end_date = endDate;

    const res = await axios
      .get(baseUrl + `/api/admin/user/widget/average-usage`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data && data.data) {
          setAvgUsageUsersWidget(data.data);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  };

  const fetchQuotaExhaustUserWidgets = async (source: any) => {
    const params: any = {};
    const dateFilter = searchParams.get('date_filter');
    const startDate = searchParams.get('start_date');
    const endDate = searchParams.get('end_date');
    if (!dateFilter || !startDate || !endDate) {
      setQuotaExhaustUsersWidget(undefined);
      return;
    }
    params.date_filter = dateFilter;
    params.start_date = startDate;
    params.end_date = endDate;

    const res = await axios
      .get(baseUrl + `/api/admin/user/widget/quota-exhaust`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data && data.data) {
          setQuotaExhaustUsersWidget(data.data);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  };

  const fetchUsers = async (source: any, search: string | undefined, resetPage: boolean) => {
    const params: any = {};
    params.page = currentPage;
    params.search = search;

    if (searchParams.size > 0) {
      searchParams.forEach((value, key) => {
        if (key !== 'date_filter' && key !== 'start_date' && key !== 'end_date' && key != 'quota') {
          const itemList = value.split(',');
          const updatedValues = [];
          for (const val of itemList) {
            const valueList = val.split('|');
            updatedValues.push(valueList[0]);
          }
          value = updatedValues.join(',');
        }
        params[key] = value;
      });
    }

    if (orderBy !== '' && orderByType !== '') {
      params.orderBy = orderBy;
      params.orderByType = orderByType;
    }

    const config: any = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    };
    if (source) {
      config.cancelToken = source.token;
    }
    const res = await axios
      .get(baseUrl + `/api/admin/user/list`, config)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data && data.data && data.data.data) {
          setCount(data.data.pagination.total_records);
          setUsers(data.data.data);
          const totalPageCount = Math.ceil(
            parseInt(data.data.pagination.total_records) / PaginationConstants.ItemsPerPage,
          );
          setlastPage(totalPageCount);
          if (resetPage) {
            setCurrentPage(1);
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
        }
        if (axios.isCancel(error)) return;
      });
  };

  const downloadXls = async () => {
    const params: any = {};
    if (search) {
      params.search = search;
    }
    if (searchParams.size > 0) {
      searchParams.forEach((value, key) => {
        if (key == 'date_filter' || key == 'start_date' || key == 'end_date') {
          return;
        }
        const itemList = value.split(',');
        const updatedValues = [];
        for (const val of itemList) {
          const valueList = val.split('|');
          updatedValues.push(valueList[0]);
        }
        value = updatedValues.join(',');
        params[key] = value;
      });
    }
    const res = await axios
      .get(baseUrl + `/api/admin/user/exportXls`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        openInNewTab(data.data);
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
        }
      });
  };

  async function isEmailAvailable() {
    if (state.id === '') {
      const res = await axios
        .get(baseUrl + `/api/users/emailExists?email=` + getValues().email, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          if (data.data) {
            setError('email', {
              type: '400',
            });
          } else {
            clearErrors('email');
          }
          return data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function deactivate(accountStaus: boolean): void {
    addUser(accountStaus);
  }

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const addUser = (deactivate: boolean) => {
    const userObj: any = {};
    userObj.firstName = getValues('firstName').trim();
    userObj.lastName = getValues('lastName').trim();
    userObj.email = getValues('email').trim();
    userObj.quotaUs = getValues('quotaUs');
    userObj.quotaCa = getValues('quotaCa');
    userObj.accountType = getValues('accountType');
    userObj.role = getValues('role');

    const formValidation = trigger();
    formValidation.then((val) => {
      if (val) {
        upsertUserData(userObj, deactivate);
      }
    });
  };

  const upsertUserData = (userObj: any, deactivate: boolean) => {
    const userData = {
      id: state.id,
      first_name: userObj.firstName,
      last_name: userObj.lastName,
      email: userObj.email,
      send_welcome_email: false,
      send_email_invite: true,
      account_type: userObj.accountType,
      role: userObj.role,
      quota: {
        US: Number(userObj.quotaUs),
        CA: Number(userObj.quotaCa),
      },
      status: deactivate ? 'Inactive' : 'Active',
    };
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (state.id == '') {
      axios
        .post(baseUrl + '/api/admin/user/create', userData, config)
        .then((response) => {
          toast?.pushSuccess('User added successfully!', 5000);
          onModalClose();
          fetchUsers(undefined, search, true);
        })
        .catch((error) => {
          if (error?.response?.data?.status === 400) {
            toast?.pushError('User account already exists!', 5000);
          }
        });
    } else {
      axios
        .put(baseUrl + '/api/admin/user/edit/' + state.id, userData, config)
        .then((response) => {
          toast?.pushSuccess('User information updated!', 5000);
          const user = JSON.parse(localStorage.getItem('userData') || '{}');
          if (user.email === userData.email) {
            getUser();
          }
          onModalClose();
          fetchUsers(undefined, search, false);
        })
        .catch();
    }
  };

  const getUser = async () => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    const res = await axios
      .get(baseUrl + `/api/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        localStorage.setItem('userData', JSON.stringify(data.data));
        window.dispatchEvent(new Event('storage'));
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
        } else if (error?.response?.data?.status === 400) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
          toast?.pushError(error?.response?.data?.error, 5000);
        }
      });
  };

  const fetchPreFilterData = async (source: any) => {
    const res = await axios
      .get(baseUrl + '/api/config/filter/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setOrderFilterData(response.data.data);
        const dataList: DropdownItem[] = [];
        for (const data of response.data.data) {
          const filterObj = {
            id: data.key,
            name: data.label,
          };
          dataList.push(filterObj);
        }
        setPreFilterData(dataList);
        return response.data;
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  };

  const onModalClose = () => {
    setBulkEdit(false);
    reset(defaultAddUserValues);
    closeModal();
  };

  const toggleOption = (selectedItem: MultiDropdownItem) => {
    setSelectedSubFilter((prevSelected) => {
      // if it's in, remove
      let newArray: MultiDropdownItem[] = [];
      if (prevSelected) {
        newArray = [...prevSelected];
      }

      const found = arrayIncludes(newArray, selectedItem);

      if (found) {
        return newArray.filter(
          (item) =>
            item.value != selectedItem.value ||
            (item.value == selectedItem.value && item.parentKey != selectedItem.parentKey),
        );
        // else, add
      } else {
        newArray.push(selectedItem);
        return newArray;
      }
    });
  };

  const updateQuotaOption = (selectedItem: UserFilterQuota, key: string, updatedValue: string) => {
    setSelectedSubFilterQuota((prevSelected) => {
      if (prevSelected) {
        let newArray: UserFilterQuota[] = [];
        if (prevSelected) {
          newArray = [...prevSelected];
        }

        let found = false;
        for (const ps of newArray) {
          if (ps.key == selectedItem.key) {
            for (const opt of ps.options) {
              if (opt.label == key) {
                opt.value = parseInt(updatedValue);
                found = true;
              }
            }
          }
        }
        if (!found) {
          for (const opt of selectedItem.options) {
            if (opt.label == key) {
              opt.value = parseInt(updatedValue);
            }
          }
          newArray.push(selectedItem);
        }

        return newArray;
      } else {
        for (const opt of selectedItem.options) {
          if (opt.label == key) {
            opt.value = parseInt(updatedValue);
          }
        }
        return [selectedItem];
      }
    });
  };

  const removeQuotaOption = (selectedItem: UserFilterQuota) => {
    setSelectedSubFilterQuota((prevSelected) => {
      if (prevSelected) {
        let newArray: UserFilterQuota[] = [];
        if (prevSelected) {
          newArray = [...prevSelected];
        }
        return newArray.filter((item) => item.key != selectedItem.key);
      }
    });
  };

  const arrayIncludes = (list: MultiDropdownItem[], item: MultiDropdownItem) => {
    let found = false;
    for (const arrayItem of list) {
      if (arrayItem.value == item.value && arrayItem.parentKey == item.parentKey) {
        found = true;
        break;
      }
    }

    return found;
  };

  const clearSubFilter = (parentKey: string) => {
    if (parentKey != 'quota') {
      setSelectedSubFilter((prevSelected) => {
        let newArray: MultiDropdownItem[] = [];
        if (prevSelected) {
          newArray = [...prevSelected];
        }
        return newArray.filter((item) => item.parentKey != parentKey);
      });
    } else {
      setSelectedSubFilterQuota(undefined);
    }
  };

  const clearAllBadges = () => {
    setSelectedSubFilter(undefined);
    setSelectedSubFilterQuota(undefined);
    setSelectedFilter(undefined);
  };

  const handleFilter = (selectedItem: DropdownItem) => {
    resetSubFilterData(selectedItem.id);
    setSelectedFilter(selectedItem);
    setHideDropdownOptions(false);
  };

  const onToggle = (isOpen: boolean) => {
    setShowFilters(!isOpen);
    // If isOpen=true then it means close action is triggered
    if (isOpen) {
      setSelectedSubFilter([]);
      setSelectedSubFilterQuota(undefined);
      setSubFilterData([]);
      setSelectedFilter(undefined);
    }
  };

  const resetSubFilterData = (selectedKey: string) => {
    if (orderFilterData) {
      if (selectedKey != 'quota') {
        const dataList: MultiDropdownItem[] = [];
        for (const data of orderFilterData) {
          if (data.key === selectedKey) {
            for (const opt of data.options) {
              const castOpt = opt as MultiDropdownItem;
              const dataTempObj: MultiDropdownItem = {
                value: castOpt.value,
                label: castOpt.label,
                parentKey: selectedKey,
              };
              dataList.push(dataTempObj);
            }
            break;
          }
          if (dataList.length > 0) {
            break;
          }
        }
        setSubFilterData(dataList);
      } else {
        const userQuotaDataList: UserFilterQuota[] = [];
        for (const quotaOpt of orderFilterData) {
          if (quotaOpt.key === selectedKey) {
            for (const opt of quotaOpt.options) {
              const castOpt = opt as UserFilterQuota;

              const quotaObj: any = {
                parentKey: quotaOpt.key,
                key: castOpt.key,
                label: castOpt.label,
                options: [],
              };
              for (const opt of castOpt.options) {
                const optObj = {
                  label: opt.label,
                };
                quotaObj.options.push(optObj);
              }
              userQuotaDataList.push(quotaObj);
            }
          }
        }
        setSubFilterQuotaData(userQuotaDataList);
      }
    }
  };

  const updateSearchParams = () => {
    if (!selectedSubFilter && !selectedSubFilterQuota && !dateValue) {
      setSearchParams({});
    }
    const paramMap = new Map<string, string>();
    if (selectedSubFilter) {
      for (const item of selectedSubFilter) {
        const mapValue = paramMap.get(item.parentKey);
        if (mapValue) {
          const newValue = `${mapValue},${item.value}|${item.label}`;
          paramMap.set(item.parentKey, newValue);
        } else {
          const newValue = `${item.value}|${item.label}`;
          paramMap.set(item.parentKey, newValue);
        }
      }
    }
    if (selectedSubFilterQuota && selectedSubFilterQuota.length > 0) {
      const paramReqQuotaObj: any = {};
      for (const item of selectedSubFilterQuota) {
        paramReqQuotaObj[item.key] = {};
        for (const opt of item.options) {
          paramReqQuotaObj[item.key][opt.label] = opt.value;
          paramReqQuotaObj[item.key]['parentKey'] = item.label;
        }
      }

      paramMap.set('quota', JSON.stringify(paramReqQuotaObj));
    }
    if (dateValue.startDate != null && dateValue.endDate != null) {
      paramMap.set('date_filter', 'custom');
      paramMap.set('start_date', dateValue.startDate);
      paramMap.set('end_date', dateValue.endDate);
    }
    if (paramMap.size > 0) {
      const searchParamObj: any = {};

      for (const [key, value] of paramMap) {
        searchParamObj[key] = value;
      }
      setSearchParams(searchParamObj);
    } else {
      setSearchParams({});
    }
  };

  const getAccountTypeOptions = () => {
    return [
      {
        value: 'AMO',
        name: 'AMO',
      },
      {
        value: 'Channel',
        name: 'Channel',
      },
      {
        value: 'Direct',
        name: 'Direct',
      },
      {
        value: 'SDR',
        name: 'SDR',
      },
      {
        value: 'Unassigned',
        name: 'Unassigned',
      },
    ];
  };

  const getAdminRoleOptions = () => {
    return userRole;
  }

  const getUserStatusOptions = () => {
    return userStatus;
  }

  const handleBlur = (event: any) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    const formValues = getValues();
    setValue(fieldName, fieldValue);
  };

  const applySorting = (columnName: string) => {
    if (orderBy === columnName && orderByType === 'asc') {
      setOrderBy(columnName);
      setOrderByType('desc');
      setCurrentPage(1);
    } else {
      setOrderBy(columnName);
      setOrderByType('asc');
      setCurrentPage(1);
    }
  };

  const { modal, openModal, closeModal } = useModal({
    children: (
      <>
        <div>
          <div className='flex justify-end'>
            <button
              type='button'
              className='pr-5 pt-3 text-xl font-light'
              onClick={() => onModalClose()}
            >
              &times;
            </button>
          </div>
          <p className='text-center text-xl font-bold'>
            {state.id != '' ? 'Edit user information' : 'Add new user'}
          </p>
          <form className='w-full px-8 pb-8 pt-6'>
            { !bulkEdit &&
              <div className='columns-2 py-2'>
                <InputFormField
                  label='First Name'
                  name='firstName'
                  type='text'
                  register={register}
                  validation={bulkEdit ? {} : { required: 'This field is required' }}
                  errors={errors}
                  isRequired
                  isRequiredLabel
                  handleChange={handleChange}
                />
                <InputFormField
                  label='Last Name'
                  name='lastName'
                  type='text'
                  register={register}
                  validation={bulkEdit ? {} : { required: 'This field is required' }}
                  errors={errors}
                  isRequired
                  isRequiredLabel
                  handleChange={handleChange}
                />
              </div>
            }
            <div className='py-2'>
              <InputFormField
                inputType='select'
                options={getAccountTypeOptions()}
                value={selectedOption}
                label='Account Type'
                name='accountType'
                isRequired={!bulkEdit}
                isRequiredLabel={!bulkEdit}
                register={register}
                validation={bulkEdit ? {} : { required: 'This field is required' }}
                errors={errors}
                onChange={(event: any) => setSelectedOption(event.target.value)}
                onBlur={handleBlur}
              />
            </div>
            { !bulkEdit &&
              <div className='py-2'>
                <InputFormField
                  label='Email'
                  name='email'
                  type='text'
                  register={register}
                  validation={bulkEdit ? {} : { required: 'This field is required' }}
                  errors={errors}
                  disabled={state.disabled}
                  handleChange={handleChange}
                  onBlur={isEmailAvailable}
                  isRequired
                  isRequiredLabel
                />
                <p>{state.disabled}</p>
                {errors.email && <p className='text-xs text-red-500'>Email already in use.</p>}
              </div>
            }
            <div className='py-2'>
              <InputFormField
                inputType='select'
                options={getAdminRoleOptions()}
                value={selectedRole}
                label='Role'
                name='role'
                isRequired={!bulkEdit}
                isRequiredLabel={!bulkEdit}
                register={register}
                validation={bulkEdit ? {} : { required: 'This field is required' }}
                errors={errors}
                onChange={(event: any) => setSelectedRole(event.target.value)}
                onBlur={handleBlur}
              />
            </div>
            <div className='columns-2 py-2'>
              <div>
                <label className='mb-2 block text-sm font-medium dark:text-white'>Quota</label>
                <div className='break-inside-avoid-column'>
                  <div className='relative flex items-center'>
                    {errors && errors['quotaUs'] && (
                      <div className='pointer-events-none absolute inset-y-0 right-10 top-1 flex items-center pl-3'>
                        <ExclemationCircleFill />
                      </div>
                    )}
                    <input
                      id='quotaCa'
                      type='text'
                      inputMode='numeric'
                      min='0'
                      {...register('quotaCa', {
                        required: (bulkEdit ? false : 'This field is required'),
                        min: { value: 0, message: 'Quota must be greater than or equal to 0' },
                      })}
                      className={`block w-full rounded-md border-gray-200 bg-white py-3 pl-10 pr-4 text-sm drop-shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 ${getClass('quotaCa')}`}
                      onChange={(ev) => handleChange(ev)}
                      required={!bulkEdit}
                    />
                    <div className='absolute left-4'>
                      <CaIcon />
                    </div>
                  </div>
                  <div className='flex flex-row'>
                    {errors && errors['quotaCa'] && (
                      <p className='mt-2 text-xs text-red-500'>{errors['quotaCa'].message}</p>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label className='mb-2 block text-sm font-medium dark:text-white'>&nbsp;</label>
                <div className='break-inside-avoid-column'>
                  <div className='relative flex items-center'>
                    {errors && errors['quotaUs'] && (
                      <div className='pointer-events-none absolute inset-y-0 right-10 top-1 flex items-center pl-3'>
                        <ExclemationCircleFill />
                      </div>
                    )}
                    <input
                      id='quotaUs'
                      type='text'
                      inputMode='numeric'
                      min='0'
                      {...register('quotaUs', {
                        required: (bulkEdit ? false : 'This field is required'),
                        min: { value: 0, message: 'Quota must be greater than or equal to 0' },
                      })}
                      className={`block w-full rounded-md border-gray-200 bg-white py-3 pl-10 pr-4 text-sm drop-shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 ${getClass('quotaUs')}`}
                      onChange={(ev) => handleChange(ev)}
                      required={!bulkEdit}
                    />
                    <div className='absolute left-4'>
                      <UsIcon />
                    </div>
                  </div>
                  <div className='flex flex-row'>
                    {errors && errors['quotaUs'] && (
                      <p className='mt-2 text-xs text-red-500'>{errors['quotaUs'].message}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='py-2'>
              { bulkEdit && <InputFormField
                inputType='select'
                options={getUserStatusOptions()}
                value={selectedStatus}
                label='Status'
                name='status'
                register={register}
                errors={errors}
                onChange={(event: any) => setSelectedStatus(event.target.value)}
                onBlur={handleBlur}
                isRequired={!bulkEdit}
                isRequiredLabel={!bulkEdit}
              />
              }
           </div>
          </form>
        </div>
        <div className='justify-between flex'>
          <div className='flex-1 px-5 py-6'>
            {!bulkEdit && (
              state.id != '' && state.status === 'Inactive' ? (
                <Button type='outline' rounded={true} onClick={() => deactivate(false)}>
                  Activate
                </Button>
              ) : state.status === 'Active' ? (
                <Button type='outlineRed' color='red' rounded={true} onClick={() => deactivate(true)}>
                  Deactivate
                </Button>
              ) : (
                ''
              )
            )}
          </div>
          <div className='px-5 py-6 '>
            <Button type='outline' rounded={true} onClick={() => onModalClose()}>
              Cancel
            </Button>
            <Button className='ml-3' onClick={() => { bulkEdit ? bulkUpdateUsers() : addUser(false)}}>
              {state.id != '' ? 'Save changes' : 'Add new user'}
            </Button>
          </div>
        </div>
      </>
    ),
  });

  const bulkUploadUsers = () => {
    const form = new FormData();

    form.append('file', bulkUploadFile);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    };

    setBulkUploadInProgress(true)

    axios
      .post(baseUrl + '/api/admin/user/bulkUpload', form, config)
      .then((response) => {
        setBulkUploadInProgress(false)
        setBulkUploadResponse(response.data.data);
        fetchUsers(undefined, search, true);
      })
      .catch((error) => {
        console.log(error);
        onBulkUploadModalClose()
      });
  }

  const handleFileChange = (event: any) => {
    setBulkUploadFile(event.target.files[0])
    setBulkUploadError('')
  }

  const resetBulkUploadModal = () => {
    setBulkUploadFile(null);
    setBulkUploadError('');
    setBulkUploadInProgress(false);
    setBulkUploadResponse({});
  }

  const onBulkUploadModalOpen = () => {
    resetBulkUploadModal();
    openBulkUploadModal();
  }

  const onBulkUploadModalClose = () => {
    resetBulkUploadModal();
    closeBulkUploadModal();
  }

  const onBulkUploadUsers = () => {
    console.log(bulkUploadFile)
    if (bulkUploadFile) {
      if (bulkUploadFile.type === 'text/csv') {
        bulkUploadUsers()
      } else {
        setBulkUploadError('Only CSV files are allowed')
      }
    } else {
      setBulkUploadError('Please select a file')
    }
  }

  const { modal: bulkUploadModal, openModal: openBulkUploadModal, closeModal: closeBulkUploadModal } = useModal({
    children: (
      <>
        <div>
          <div className='flex justify-end'>
            <button
              type='button'
              className='pr-5 pt-3 text-xl font-light'
              onClick={() => onBulkUploadModalClose()}
              disabled={bulkUploadInProgress}
            >
              &times;
            </button>
          </div>
          <p className='text-center text-xl font-bold'>
            Bulk upload users
          </p>
          {bulkUploadResponse.status ? <>
            <div className='container'>
              <p className="mt-5 text-l">The file was successfully uploaded.</p>
              <p>{bulkUploadResponse.added || 0} user(s) were added. {bulkUploadResponse.updated || 0} user(s) were updated.</p>
              {(Object.keys(bulkUploadResponse.errors).length > 0) &&
                <>
                  <p className="mt-5 text-red-500">There was an error importing the following rows:</p>
                  <ul className="mb-5">
                    {Object.keys(bulkUploadResponse.errors).map((key: string) => (
                      <li key={key} className='list-inside list-disc text-red-500'>{`Row ${Number(key) + 1} - ${bulkUploadResponse.errors[key]}`}</li>
                    ))}
                  </ul>
                </>
              }
              <div className='py-6 text-center'>
                <Button type='outline' rounded={true} onClick={() => onBulkUploadModalClose()} disabled={bulkUploadInProgress}>
                  Close
                </Button>
              </div>
            </div>
          </> : <>
          <form className='w-full mt-2 px-8 pb-8 pt-6'>
            <label className="mb-2 block text-sm font-medium dark:text-white">Please select the users file to upload</label>
            <div className="flex items-center justify-between">
              <input
                id="file"
                type="file"
                accept=".csv,text/csv"
                name="file"
                className="block w-full rounded-lg py-3 text-sm drop-shadow-sm disabled:opacity-50 border-gray-200"
                onChange={handleFileChange}
                disabled={bulkUploadInProgress}
              />
              <span className="mb-2 block text-sm text-gray-500 dark:text-neutral-500">Required</span>
            </div>
            {bulkUploadError && (
              <p className="mt-2 text-xs text-red-500">{bulkUploadError}</p>
            )}
            <p className="mt-5 text-sm">
              Download a sample CSV from <Link to={`${process.env.PUBLIC_URL}/samples/users.csv`} className='text-zinc-950 underline decoration-zinc-950/50 data-[hover]:decoration-zinc-950' target='blank'>here</Link>
            </p>
          </form>
          <div className='justify-between flex'>
            <div className='flex-1 px-5 py-6'>
            </div>
            <div className='px-5 py-6 flex items-center'>
              <Button type='outline' rounded={true} onClick={() => onBulkUploadModalClose()} disabled={bulkUploadInProgress}>
                Cancel
              </Button>
              <Button className='ml-3' onClick={() => onBulkUploadUsers()} disabled={bulkUploadInProgress}>
                {bulkUploadInProgress ? <>
                  <Loader/> Uploading
                </>: <>Upload</>
                }
              </Button>
            </div>
          </div>
          </>
          }
        </div>
      </>
    )
  })

  const checkSelectAll = () => {
    return selectedAllUsers || (selectedUsers.length && selectedUsers.length === users.length)
  }
  const handleSelectAll = () => {
    setSelectedUsers([]);
    setSelectedAllUsers(!checkSelectAll());
  }
  const checkSelection = (id: any) => {
    return selectedAllUsers || selectedUsers.includes(id)
  }
  const handleSelection = (id: any) => {
    if (selectedAllUsers) {
      const filteredUsers = users.filter((u: any) => (u.id !== id))
      const selectedUserIds = filteredUsers.map((u: any) => (u.id))
      setSelectedAllUsers(false);
      setSelectedUsers(selectedUserIds)
    } else {
      if (selectedUsers.includes(id)) {
        const userIds = selectedUsers.filter((userId: any) => (userId !== id))
        setSelectedUsers(userIds)
      } else {
        const userIds = selectedUsers.concat(id)
        setSelectedUsers(userIds)
      }
    }
  }

  const selectedUserIds = () => {
    if (selectedAllUsers) {
      return users.map((u: any) => (u.id))
    } else {
      return selectedUsers
    }
  }

  function openBulkEditModal() {
    setBulkEdit(true)
    reset(defaultAddUserValues);
    openEditModal({})
  }

  const bulkUpdateUsers = () => {
    const formValidation = trigger();
    formValidation.then((val) => {
      if(val) {
        const formData = {
          accountType: getValues('accountType'),
          role: getValues('role'),
          quotaUS: Number(getValues('quotaUs')),
          quotaCA: Number(getValues('quotaCa')),
          status: getValues('status'),
        };

        if (Object.values(formData).every((value: any) => (!value))){
          toast?.pushError('Please select some value(s) to bulk update!', 5000);
        } else {
          const data = {
            ...formData,
            ids: selectedUserIds(),
          }

          const config = {
            headers: { Authorization: `Bearer ${token}` },
          };

          axios
            .put(baseUrl + '/api/admin/user/bulkUpdate', data, config)
            .then((response) => {
              toast?.pushSuccess('Users information updated!', 5000);
              onModalClose();
              setSelectedUsers([]);
              setSelectedAllUsers(false);
              fetchUsers(undefined, search, true);
            })
            .catch();
        }
      }
    });
  }

  const generateMonths = () => {
    const currentMonthIndex = new Date().getMonth() + 1;


    const orderedMonths = [
      ...months.slice(currentMonthIndex),
      ...months.slice(0, currentMonthIndex)
    ];

    return orderedMonths.map((month) => ({
      name: month,
      value: month
    }));
  };

  const exportMonthlyUserReport = async () => {
    setReportGenerationInProgress(true);
    try {
      const res = await axios
      .get(baseUrl + '/api/admin/user/exportMonthlyReports', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          month: selectedMonth
        }
      });
      setmonthlyReportErrors('');
      openInNewTab(res.data?.data);
    } catch (error: any) {
      console.log(error?.response?.data);
      setmonthlyReportErrors(error?.response?.data?.data);
    }
    setReportGenerationInProgress(false);
  };

  const { modal: monthlyReportsModal, openModal: openMonthlyReportsModal, closeModal: closeMonthlyReportsModal } = useModal({
    children: (
      <>
        <div>
          <div className='flex justify-end'>
            <button
              type='button'
              className='pr-5 pt-3 text-xl font-light'
              onClick={() => closeMonthlyReportsModal()}
            >
              &times;
            </button>
          </div>

          <p className='text-center text-xl font-bold'>Monthly User Report</p>

          <div className="container">
            {
              monthlyReportErrors ? <>
                <p className="mt-5 text-red-500">
                  {monthlyReportErrors}
                </p>
              </> : <></>
            }
          </div>

          <div className='grid-row gap-4 p-2'>
            <SelectInput
              label='Select a Month'
              options={generateMonths()}
              name='Month'
              onChange={(event) => setSelectedMonth(event.target.value)}
              value={selectedMonth}
            ></SelectInput>
          </div>

          <div className='justify-between flex'>
            <div className='flex-1 px-5 py-6'>
            </div>
            <div className='px-5 py-6 flex items-center'>
              <Button type='outline' rounded={true} onClick={() => closeMonthlyReportsModal()} disabled={reportGenerationInProgress}>
                Cancel
              </Button>
              <Button className='ml-3' onClick={() => exportMonthlyUserReport()} disabled={reportGenerationInProgress}>
                { reportGenerationInProgress ? <><Loader/> Exporting</> : <>Export</> }
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  });

  return (
    <>
      <Wrapper className='flex flex-col px-20'>
        <div className='flex justify-between'>
          <div className='w-1/3'>
            <p className='text-left text-2xl font-semibold'>Users ({count})</p>
          </div>
          <div className='flex w-2/3 justify-end space-x-4'>
            <Button type='outlineGreen' rounded={true} onClick={() => openMonthlyReportsModal()}>
              Monthly Reports
            </Button>
            <Button type='outlineGreen' rounded={true} onClick={() => downloadXls()}>
              Export to XLSX
            </Button>
            <Button type='outlineGreen' rounded={true} onClick={() => openEmptyStateModal()}>
              Add new user
            </Button>
            <Button type='outlineGreen' rounded={true} onClick={() => onBulkUploadModalOpen()}>
              Bulk upload users
            </Button>
            <Button type='outlineGreen' rounded={true} onClick={() => openBulkEditModal()} disabled={!selectedAllUsers && !selectedUsers.length}>
              Bulk update users
            </Button>
          </div>
        </div>
        <div className='flex w-full justify-start pt-2 '>
          <div className='w-64'>
            <Datepicker
              inputClassName={
                'relative transition-all duration-300 py-2.5 pl-4 pr-8 w-full border-gray-300 dark:bg-slate-800 dark:text-white/80 dark:border-slate-600 rounded-lg text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed ${border} ${ring} font-semibold tracking-tighter leading-4 text-gray-800 drop-shadow-lg'
              }
              showShortcuts={true}
              onChange={handleValueChange}
              value={dateValue}
            />
          </div>
          <div className='ml-4 flex h-24 w-1/5 flex-col justify-end rounded-xl bg-white p-3 drop-shadow-lg'>
            <div className='grid h-24 grid-rows-2 content-center items-center'>
              <div className='col-span-12 text-xs font-medium leading-4 text-gray-800'>
                Total active users
              </div>
              <div className='col-span-5 justify-self-center'>
                <div className='flex items-center justify-center'>
                  <span className='self-center text-lg'>
                    {activeUsersWidget && activeUsersWidget?.curCount}
                  </span>

                  {activeUsersWidget && activeUsersWidget?.change_percent > '0' ? (
                    <>
                      <span className='ml-3 mt-1'>
                        <CaretUpGreenIcon></CaretUpGreenIcon>
                      </span>
                      <span className='mt-1 text-xs text-teal-500'>
                        {activeUsersWidget && activeUsersWidget?.change_percent}%
                      </span>
                    </>
                  ) : (
                    <>
                      <span className='ml-3 mt-1'>
                        <CaretDownRedIcon></CaretDownRedIcon>
                      </span>
                      <span className='mt-1 text-xs text-red-500'>
                        {activeUsersWidget && activeUsersWidget?.change_percent}%
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className='col-span-7 justify-self-center'>
                <div>
                  <LineChart
                    width={180}
                    height={40}
                    data={activeUsersWidgetData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <Line
                      type='monotone'
                      dataKey='count'
                      stroke='#8884d8'
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </div>
              </div>
            </div>
          </div>
          <div className='ml-4 flex h-24 w-1/5 flex-col justify-end rounded-xl bg-white p-3 drop-shadow-lg'>
            <div className='grid h-24 grid-rows-2 content-center'>
              <div className='col-span-12 text-xs font-medium leading-4 text-gray-800'>
                Average monthly usage
              </div>
              <div className='col-span-5 justify-self-center'>
                <span className='inline-flex items-center justify-center'>
                  <span className='px-2'>
                    <CaIcon></CaIcon>
                  </span>
                  <span className='text-lg'>{avgUsageUsersWidget?.['CA']?.current ?? 0}</span>
                  {avgUsageUsersWidget && avgUsageUsersWidget?.['CA']?.change_percent > '0' ? (
                    <>
                      <span className='ml-3 mt-1'>
                        <CaretUpGreenIcon></CaretUpGreenIcon>
                      </span>
                      <span className='mt-1 text-xs text-teal-500'>
                        {avgUsageUsersWidget && avgUsageUsersWidget?.['CA']?.change_percent}%
                      </span>
                    </>
                  ) : (
                    <>
                      <span className='ml-3 mt-1'>
                        <CaretDownRedIcon></CaretDownRedIcon>
                      </span>
                      <span className='mt-1 text-xs text-red-500'>
                        {avgUsageUsersWidget && avgUsageUsersWidget?.['CA']?.change_percent}%
                      </span>
                    </>
                  )}
                </span>
              </div>
              <div className='col-span-2 content-center justify-self-center px-2'>
                <SeperatorIcon></SeperatorIcon>
              </div>
              <div className='col-span-5 justify-self-center'>
                <span className='inline-flex items-center justify-center'>
                  <span className='px-2'>
                    <UsIcon></UsIcon>
                  </span>
                  <span className='text-lg'>{avgUsageUsersWidget?.['US']?.current ?? 0}</span>
                  {avgUsageUsersWidget && avgUsageUsersWidget?.['US']?.change_percent > '0' ? (
                    <>
                      <span className='ml-3 mt-1'>
                        <CaretUpGreenIcon></CaretUpGreenIcon>
                      </span>
                      <span className='mt-1 text-xs text-teal-500'>
                        {avgUsageUsersWidget && avgUsageUsersWidget?.['US']?.change_percent}%
                      </span>
                    </>
                  ) : (
                    <>
                      <span className='ml-3 mt-1'>
                        <CaretDownRedIcon></CaretDownRedIcon>
                      </span>
                      <span className='mt-1 text-xs text-red-500'>
                        {avgUsageUsersWidget && avgUsageUsersWidget?.['US']?.change_percent}%
                      </span>
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className='ml-4 flex h-24 w-1/5 flex-col justify-end rounded-xl bg-white p-4 drop-shadow-lg'>
            <div className='grid h-24 grid-rows-2 content-center items-center'>
              <div className='col-span-12 text-xs font-medium leading-4 text-gray-800'>
                Don’t have any quota left
              </div>
              <div className='col-span-5 justify-self-center'>
                <div className='flex items-center justify-center'>
                  <span className='self-center text-lg'>
                    {quotaExhaustUsersWidget && quotaExhaustUsersWidget?.curCount}
                  </span>

                  {quotaExhaustUsersWidget && quotaExhaustUsersWidget?.change_percent > '0' ? (
                    <>
                      <span className='ml-3 mt-1'>
                        <CaretUpGreenIcon></CaretUpGreenIcon>
                      </span>
                      <span className='mt-1 text-xs text-teal-500'>
                        {quotaExhaustUsersWidget && quotaExhaustUsersWidget?.change_percent}%
                      </span>
                    </>
                  ) : (
                    <>
                      <span className='ml-3 mt-1'>
                        <CaretDownRedIcon></CaretDownRedIcon>
                      </span>
                      <span className='mt-1 text-xs text-red-500'>
                        {quotaExhaustUsersWidget && quotaExhaustUsersWidget?.change_percent}%
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className='col-span-7 justify-self-center'>
                <div>
                  <LineChart
                    width={180}
                    height={40}
                    data={quotaExhaustUsersWidget && quotaExhaustUsersWidget.data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <Line
                      type='monotone'
                      dataKey='count'
                      stroke='#8884d8'
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-between py-3'>
          <div className='relative mr-5 flex h-12 w-full items-center overflow-hidden rounded-lg border border-gray-200 bg-white drop-shadow-lg focus-within:shadow-lg'>
            <SearchInput
              onChange={searchData}
              search={search}
              placeholder='Search by name, account type or email'
            ></SearchInput>
          </div>
          <div className='w-fit'>
            <Button
              type='solidWithBorder'
              className='focus:none border border-gray-200 bg-white text-gray-800 drop-shadow-lg hover:bg-gray-200'
              rounded={true}
              onClick={() => onToggle(showFilters)}
            >
              <ListIconBlack></ListIconBlack> <span className='text-gray-800'>Filter</span>
            </Button>
          </div>
        </div>
        {showFilters && (
          <div className='z-100 flex flex-row flex-wrap items-center pb-2'>
            {preFilterData && (
              <div className='mr-2 w-36 items-center'>
                <DropdownComponent
                  id='filterdropdown'
                  title='Add Filter'
                  data={preFilterData}
                  onSelect={handleFilter}
                  listHeader='CATEGORIES'
                />
              </div>
            )}
            {subFilterData &&
              subFilterData.length > 0 &&
              selectedFilter &&
              selectedFilter.id != 'quota' && (
                <div className='mr-2 w-52 items-center'>
                  <MultiDropdownWithSearch
                    id='subfilterdropdown'
                    title={selectedFilter?.name}
                    data={subFilterData}
                    parentKey={selectedFilter ? selectedFilter?.id : ''}
                    onSelect={toggleOption}
                    clearSubFilter={clearSubFilter}
                    selected={selectedSubFilter}
                    searchPlaceholder={filterTypePlaceholderMap.get(
                      selectedFilter ? selectedFilter.id : '',
                    )}
                    hideDropdownOptions={hideDropdownOptions}
                  />
                </div>
              )}
            {subFilterQuotaData &&
              subFilterQuotaData.length > 0 &&
              selectedFilter &&
              selectedFilter.id == 'quota' && (
                <div className='mr-2 w-52 items-center'>
                  <DropdownQuotaComponent
                    id='subfilterquotadropdown'
                    title={selectedFilter?.name}
                    data={subFilterQuotaData}
                    parentKey={selectedFilter ? selectedFilter?.id : ''}
                    selected={selectedSubFilterQuota}
                    onSelect={updateQuotaOption}
                    clearSubFilter={clearSubFilter}
                    hideDropdownOptions={hideDropdownOptions}
                  />
                </div>
              )}
            {((selectedSubFilter && !!selectedSubFilter.length) ||
              (selectedSubFilterQuota && !!selectedSubFilterQuota.length)) && (
              <>
                {selectedSubFilter &&
                  !!selectedSubFilter.length &&
                  selectedSubFilter.map((filter) => (
                    <div key={filter.value} className='flex items-center gap-2 self-center py-2'>
                      <DismissBadge
                        badgeText={filter.label}
                        onDismiss={() => toggleOption(filter)}
                      />
                    </div>
                  ))}
                {selectedSubFilterQuota &&
                  !!selectedSubFilterQuota.length &&
                  selectedSubFilterQuota.map((filter) => {
                    let badgeKey = '';
                    const labelList = [];
                    for (const opt of filter.options) {
                      if (opt.value > 0) {
                        if (opt.label == 'min') {
                          const labelKey = `${opt.label} > ${opt.value}`;
                          labelList.push(labelKey);
                        } else {
                          const labelKey = `${opt.label} < ${opt.value}`;
                          labelList.push(labelKey);
                        }
                      }
                    }
                    badgeKey = `${filter.key} ${labelList.join(' AND ')}`;
                    return (
                      <div key={badgeKey} className='flex items-center gap-2 py-2'>
                        <DismissBadge
                          badgeText={badgeKey}
                          onDismiss={() => removeQuotaOption(filter)}
                        />
                      </div>
                    );
                  })}
                <button
                  className='h-8 w-28 rounded-full bg-white px-2 py-1 text-sm font-medium leading-4 tracking-wide text-gray-800'
                  onClick={() => {
                    clearAllBadges();
                  }}
                >
                  Clear all
                </button>
              </>
            )}
          </div>
        )}
        <div className='py-2'>
          <Table className='relative min-w-full divide-y divide-gray-200 border-b border-gray-200 shadow drop-shadow-lg sm:rounded-lg'>
            <TableHead className='overflow-hidden bg-gray-50'>
              <TableRow>
                <TableHeader className='w-10'>
                  <input
                    type='checkbox'
                    checked={checkSelectAll()}
                    name='select-all'
                    onChange={() => handleSelectAll()}
                    className='mt-0.5 shrink-0 rounded border-gray-200 text-blue-600 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-800 dark:checked:border-blue-500 dark:checked:bg-blue-500 dark:focus:ring-offset-gray-800'
                  />
                </TableHeader>
                <TableHeader className='px-2 text-gray-500 '>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(USER_SORT_COL_MAP.name)}
                  >
                    <span className='mr-1'>SALES REPRESENTATIVE</span>
                    {orderBy === USER_SORT_COL_MAP.name ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='text-gray-500'>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(USER_SORT_COL_MAP.email)}
                  >
                    <span className='mr-1'>EMAIL</span>
                    {orderBy === USER_SORT_COL_MAP.email ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='text-gray-500'>ROLE</TableHeader>
                <TableHeader className='text-gray-500'>QUOTA</TableHeader>
                <TableHeader className='text-gray-500'>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(USER_SORT_COL_MAP.status)}
                  >
                    <span className='mr-1'>STATUS</span>
                    {orderBy === USER_SORT_COL_MAP.status ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='text-gray-500'>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(USER_SORT_COL_MAP.last_active)}
                  >
                    <span className='mr-1'>LAST ACTIVE</span>
                    {orderBy === USER_SORT_COL_MAP.last_active ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='text-gray-500'>ACTIONS</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody className='overflow-hidden bg-white'>
              {users.map((data: any) => (
                <TableRow key={data.handle}>
                  <TableCell>
                    <input
                      type='checkbox'
                      checked={checkSelection(data.id)}
                      name='select[]'
                      onChange={() => { handleSelection(data.id) }}
                      className='mt-0.5 shrink-0 rounded border-gray-200 text-blue-600 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-800 dark:checked:border-blue-500 dark:checked:bg-blue-500 dark:focus:ring-offset-gray-800'
                    />
                  </TableCell>
                  <TableCell className='text-gray-800'>
                    {data.name}{' '}
                    <Badge
                      color={
                        data.account_type == 'Direct'
                          ? 'red'
                          : data.account_type == 'SDR'
                            ? 'emerald'
                            : data.account_type == 'Channel'
                              ? 'yellow'
                              : data.account_type == 'Unassigned'
                                ? 'zinc'
                                : 'blue'
                      }
                    >
                      {data.account_type}
                    </Badge>
                  </TableCell>
                  <TableCell className='text-gray-800'>{data.email}</TableCell>
                  <TableCell className='text-gray-800'>
                    {
                      data.role == 'ADMIN'
                        ? 'Team Lead'
                        : data.role == 'MANAGER'
                          ? 'Manager'
                          : 'User'
                    }
                  </TableCell>
                  <TableCell className='text-gray-500'>
                    <span className='inline-flex items-center'>
                      <span className='px-2'>
                        <CaIcon></CaIcon>
                      </span>
                      <span>
                        <TooltipComponent message='Quota left/ Quota allocated'>
                          {data?.quota?.['CA']?.balance ?? 0}/
                          {data?.quota?.['CA']?.total_quota ?? 0}
                        </TooltipComponent>
                      </span>
                      <span className=' px-2'>
                        <SeperatorIcon></SeperatorIcon>
                      </span>
                      <span className=' px-2'>
                        <UsIcon></UsIcon>
                      </span>
                      <span>
                        <TooltipComponent message='Quota left/ Quota allocated'>
                          {data?.quota?.['US']?.balance ?? 0}/
                          {data?.quota?.['US']?.total_quota ?? 0}
                        </TooltipComponent>
                      </span>
                    </span>
                  </TableCell>
                  <TableCell className='text-zinc-500'>
                    <Badge
                      color={
                        data.status == 'Invited'
                          ? 'blue'
                          : data.status == 'Active'
                            ? 'greenTxtWht'
                            : 'redTxtWht'
                      }
                    >
                      {data.status}
                    </Badge>
                    {/* <Badge color='emerald'>{data.status}</Badge> */}
                  </TableCell>
                  <TableCell className='text-gray-800'>
                    {data.last_active ? (
                      <TooltipComponent message={format(data.last_active, 'MM/dd/yyyy HH:MM:SS')}>
                        {data.last_active ? format(data.last_active, 'MM/dd/yyyy') : ''}
                      </TooltipComponent>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>
                    <span className='inline-flex items-baseline'>
                      <span onClick={() => openEditModal(data)} className='cursor-pointer px-2'>
                        <TooltipComponent message='Edit'>
                          <EditIcon />
                        </TooltipComponent>
                      </span>
                      <span
                        onClick={() => redirectToUserOrder(data)}
                        className='cursor-pointer px-2'
                      >
                        <TooltipComponent message='Show all orders of this user'>
                          <ListIcon />
                        </TooltipComponent>
                      </span>
                      {data.status == 'Invited' && (
                        <span onClick={() => sendInviteEmail(data)} className='cursor-pointer px-2'>
                          <TooltipComponent message='Re-invite'>
                            <MailIcon />
                          </TooltipComponent>
                        </span>
                      )}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {users && users.length == 0 && (
            <div className='pt-5 text-center text-gray-500'>
              {'No data found. Please change your search filters'}
            </div>
          )}
          {users && users.length > 0 && (
            <div className='mb-5 mt-8 flex h-10 items-center justify-center'>
              <Pagination
                currentPage={currentPage}
                lastPage={lastPage}
                maxLength={5}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </Wrapper>
      {modal}
      {bulkUploadModal}
      {monthlyReportsModal}
    </>
  );
};

export default UsersList;
